import React from "react";
import PropTypes from "prop-types";
import CloseSvg from "../../../assets/images/vector/components/common/closeInit.svg";

import "./index.scss";

const SuccessSendPopup = ({ isOpened, closePopup }) => {
  if (!isOpened) {
    return null;
  }

  return (
    <div className="PgMTR-SuccessSendPopup">
      <div className="PgMTR-SuccessSendPopup-wrapper" />
      <div className="PgMTR-SuccessSendPopup-content">
        <img
          className="PgMTR-SuccessSendPopup__close"
          src={CloseSvg}
          alt="X"
          onClick={closePopup}
        />
        <div className="PgMTR-SuccessSendPopup-content__title">Thank you!</div>
        <p className="PgMTR-SuccessSendPopup-content__text">
          The AMZScout Trend Report has been sent to your email.
        </p>
        <p className="PgMTR-SuccessSendPopup-content__text">
          If you don't receive it within 10 minutes, please let us know at{" "}
          <a href="mailto:support@amzsc.amzgb.com">support@amzsc.amzgb.com</a>.
        </p>
      </div>
    </div>
  );
};

SuccessSendPopup.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default SuccessSendPopup;
